


































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ReservationInfo extends Vue {
  @Prop({
    required: true
  })
  public product!: any;
  @Prop()
  public selectLang!: any;
}
